.draggableQuestionnaryContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4em;
  }
  .list-container {
    display: flex;
    font-size: 18px;
    background-color: #eee;
    flex-direction: column;
  }
  .item-container {
    background-color: #eabfc4;
    border: 1px solid black;
    color:#004759;
    padding: 25px 70px;
    margin: 15px 50px;
    text-align: justify;
  }
  
  .boxQuestionInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 4em;
    border-radius: 5%;
    background-color: #E36DB0;
    width: 50px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  }

  .textBoldQuestion{
    font-weight: 700;
  }

  @media (max-width: 580px) { 
    .list-container {
      display: flex;
      font-size: 15px;
      background-color: #eee;
      flex-direction: column;
    }
    .item-container {
      background-color: #E36DB0;
      border: 1px solid black;
      color:#004759;
      padding: 10px 10px;
      margin: 15px 50px;
      text-align: justify;
    }

   }