
html, body {
  position: relative;
  min-height: 100%;
  font-family:'Arial Narrow!important'!important;
  color:#1C1C1B
  
}
.textBoldQuestion{
  font-weight: 700;
}

.titreColor{
  color: #AD1380;
}
